<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>团课佣金规则</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    v-loading="loading"
                    :data="tableData"
                    border
                    size="medium"
                    style="width: 100%">
                <el-table-column
                        prop="grade_name"
                        label="等级"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="content"
                        label="内容">
                    <template slot-scope="scope">
                        {{ scope.row.content }}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="150">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('coach.tag.issave')"
                                @click="edit_page(scope.row)"
                                size="mini">编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--编辑-->
            <el-dialog v-loading="loading"
                       v-if="is_auth('coach.rules.savegroup')"
                       title="编辑规则"
                       :visible.sync="editPage"
                       width="70%"
                       :close-on-press-escape="false"
                       :close-on-click-modal="false"
            >
                <el-row>
                    <el-form size="medium" ref="form" :label-width="this.env.label_width">
                        <el-col :span="24">
                            <el-form-item label="等级：">
                                <span>{{ this.grade_name }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="规则1：">
                                底薪￥
                                <el-input-number
                                        size="mini"
                                        v-model="info.rules1.base_salary"
                                        :step="0.01" :min="0" :max="9999"
                                        step-strictly>
                                </el-input-number>
                                基础
                                <el-input-number
                                        size="mini"
                                        v-model="info.rules1.base_qty"
                                        :step="1" :min="0" :max="100"
                                        step-strictly>
                                </el-input-number>
                                人
                            </el-form-item>
                            <el-form-item>
                                大团课每增加
                                <el-input-number
                                        size="mini"
                                        v-model="info.rules1.groupbig.add_qty"
                                        :step="1" :min="1" :max="100"
                                        step-strictly>
                                </el-input-number>
                                人，则佣金增加 ￥
                                <el-input-number
                                        size="mini"
                                        v-model="info.rules1.groupbig.add_salary"
                                        :step="0.01" :min="0" :max="9999"
                                        step-strictly>
                                </el-input-number>
                            </el-form-item>
                            <el-form-item>
                                小团课每增加
                                <el-input-number
                                        size="mini"
                                        v-model="info.rules1.groupsmall.add_qty"
                                        :step="1" :min="1" :max="100"
                                        step-strictly>
                                </el-input-number>
                                人，则佣金增加 ￥
                                <el-input-number
                                        size="mini"
                                        v-model="info.rules1.groupsmall.add_salary"
                                        :step="0.01" :min="0" :max="9999"
                                        step-strictly>
                                </el-input-number>
                            </el-form-item>
                            <el-form-item>
                                小团私每增加
                                <el-input-number
                                        size="mini"
                                        v-model="info.rules1.grouppt.add_qty"
                                        :step="1" :min="1" :max="100"
                                        step-strictly>
                                </el-input-number>
                                人，则佣金增加 ￥
                                <el-input-number
                                        size="mini"
                                        v-model="info.rules1.grouppt.add_salary"
                                        :step="0.01" :min="0" :max="9999"
                                        step-strictly>
                                </el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="规则2：">
                                当预约率 >=
                                <el-input-number
                                        size="mini"
                                        v-model="info.rules2.reservation_percentage"
                                        :step="1" :min="0" :max="100"
                                        step-strictly>
                                </el-input-number>
                                （%）,佣金为订单
                                <el-input-number
                                        size="mini"
                                        v-model="info.rules2.salary_percentage"
                                        :step="1" :min="0" :max="100"
                                        step-strictly>
                                </el-input-number>
                                （%）
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="">
                                <span class="form-tip">假设课程单价￥
                                    <el-input-number
                                            size="mini"
                                            v-model="example.price"
                                            :step="0.01" :min="0" :max="9999"
                                            step-strictly>
                                    </el-input-number>
                                    ，人数上限
                                    <el-input-number
                                            size="mini"
                                            v-model="example.quota"
                                            :step="1" :min="0" :max="999"
                                            step-strictly>
                                    </el-input-number>
                                    人，预约数
                                    <el-input-number
                                            size="mini"
                                            v-model="example.bookings_sum"
                                            :step="1" :min="0" :max="example.quota"
                                            step-strictly>
                                    </el-input-number>
                                    人。总金额:￥{{ this.Tool.bcmul(this.example.price, this.example.bookings_sum) }}</span>
                                <br>
                                <span class="form-tip">
                                    规则1：大团课：{{ info.rules1.base_salary }}
                                    +(({{ example.bookings_sum }}-{{ info.rules1.base_qty }})
                                    ÷{{ info.rules1.groupbig.add_qty }})向下取整
                                    ×{{ info.rules1.groupbig.add_salary }}
                                    =￥{{ rules1('groupbig') }}
                                </span><br>
                                <span class="form-tip" style="margin-left: 47px;">
                                    小团课：{{ info.rules1.base_salary }}
                                    +(({{ example.bookings_sum }}-{{ info.rules1.base_qty }})
                                    ÷{{ info.rules1.groupsmall.add_qty }})向下取整
                                    ×{{ info.rules1.groupsmall.add_salary }}
                                    =￥{{ rules1('groupsmall') }}
                                </span><br>
                                <span class="form-tip" style="margin-left: 47px;">
                                    小团私：{{ info.rules1.base_salary }}
                                    +(({{ example.bookings_sum }}-{{ info.rules1.base_qty }})
                                    ÷{{ info.rules1.grouppt.add_qty }})向下取整
                                    ×{{ info.rules1.grouppt.add_salary }}
                                    =￥{{ rules1('grouppt') }}
                                </span><br>
                                <span class="form-tip">
                                    规则2，即：
                                    ({{ example.bookings_sum }}/{{ example.quota }})
                                    {{ rules2a() }}
                                    {{ info.rules2.reservation_percentage }}%
                                    佣金:
                                    <span v-if=" rules2a()==='>='">
                                        ({{ example.price }}×{{ example.bookings_sum }})
                                        ×{{ info.rules2.salary_percentage }}%
                                        =￥{{ rules2() }}
                                    </span>
                                    <span v-else>￥{{ rules2() }}</span>
                                </span>
                            </el-form-item>
                            <el-form-item label="">
                                <span class="form-tip">两规则取佣金高者</span>
                            </el-form-item>
                        </el-col>

                    </el-form>
                </el-row>
                <el-row style="margin: 10px 0px;">
                    <el-button size="medium" type="primary" @click="save">确 定</el-button>
                    <el-button size="medium" @click="editPage = false">取 消</el-button>
                </el-row>

            </el-dialog>
            <div style="height: 20px;"></div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '团课佣金规则',
            loading: true,          // 加载状态
            tableData: [],          // 列表内容
            id: '',
            grade_name: '',
            info: {},            // 编辑数据
            editPage: false,
            example: {
                price: 89,
                quota: 15,
                bookings_sum: 10,
            },                     // 例子
        }
    },
    // 创建
    created() {
        this.getlist();
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "coach.rules.getgrouplist",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                } else {
                    this.tableData = []
                    this.$message.error(json.message);
                }
            })
        },
        // 初始化
        init() {
            this.info = {
                rules1: {
                    base_salary: 0,             // 基本工资
                    base_qty: 0,            // 基础人数
                    // 大团课
                    groupbig: {
                        add_qty: 1,         // 每增加n人
                        add_salary: 0,      // 增加n元
                    },
                    // 小团课
                    groupsmall: {
                        add_qty: 1,         // 每增加n人
                        add_salary: 0,      // 增加n元
                    },
                    // 小团私
                    grouppt: {
                        add_qty: 1,         // 每增加n人
                        add_salary: 0,      // 增加n元
                    },
                },
                rules2: {
                    reservation_percentage: 0,
                    salary_percentage: 0,
                },
            }
        },
        // 编辑页面
        edit_page(row = {}) {
            this.id = row.id
            this.grade_name = row.grade_name

            if (!this.Tool.is_empty(row.content)) {
                this.info = JSON.parse(JSON.stringify(row.content));
            } else {
                this.init()
            }

            this.editPage = true    // 显示页面
        },
        // 保存
        save() {
            let postdata = {
                api_name: 'coach.rules.savegroup',
                token: this.Tool.get_l_cache('token'),
                id: this.id,
                content: this.info,
            }
            // console.log('postdata', postdata)

            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.editPage = false
                    this.$message({
                        message: '保存成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()
                        }
                    });

                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 条件1计算
        rules1(group_type) {
            let base_salary = this.info.rules1.base_salary;
            let base = (this.example.bookings_sum - this.info.rules1.base_qty) > 0 ? parseInt((this.example.bookings_sum - this.info.rules1.base_qty) / this.info.rules1[group_type].add_qty) : 0
            let salary = this.Tool.bcadd(base_salary, this.Tool.bcmul(base, this.info.rules1[group_type].add_salary))

            return salary;
        },
        // 条件2计算
        rules2a() {
            let reservation_percentage_a = this.Tool.bcmul(this.example.bookings_sum / this.example.quota, 100).toFixed(2)
            if (reservation_percentage_a >= this.info.rules2.reservation_percentage) {
                return '>=';
            } else {
                return '<';
            }
        },
        rules2() {
            if (this.rules2a() === '>=') {
                let total = this.Tool.bcmul(this.example.price, this.example.bookings_sum)
                let salary_percentage = (this.info.rules2.salary_percentage / 100).toFixed(2)
                let salary = this.Tool.bcmul(total, salary_percentage);
                return salary;
            } else {
                return '0';
            }

        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
